.overflow-y-auto::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #272822;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, .1);
}